import React, { useEffect, useMemo, useState } from 'react';
import { Elements, PaymentElement, useElements } from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';
import { useTranslation } from '@planity/localization';
import { Spinner, Button, useModal } from '@planity/ui';
import scssStyles from './appointment_payment.modules.scss';
import { CardsList } from './cards_list';
import { Localize, useStripeElements } from '@planity/components';
import classNames from 'classnames/bind';
import styles from './appointment_payment.modules.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import {
	noop,
	isStripeError,
	usePrevious,
	PAYMENT_TYPE_CARD,
	formatPrice,
	INVALID_NAME_FOR_USER_USING_KLARNA,
	PAYMENT_TYPE_KLARNA
} from '@planity/helpers';
import credentials from '@planity/credentials';
import { isNativeApp } from '@planity/webview';
import { AcceptSaveCardSelector } from './accept_save_card_selector';
import { useUserData } from '@planity/context';
import { isBefore } from 'date-fns';
import { PaymentWarningModal } from './payment_warning_modal';

export const StripePayment = ({
	canDefault,
	getNewIntent,
	amount,
	amountWithoutFees,
	clientSecret,
	isPrepayment,
	customerID,
	paymentMethods,
	setDisplayAddCard,
	displayAddCard,
	formatAppointmentForLambda,
	formattedSteps,
	steps,
	userId,
	appointment,
	isPro,
	userPaysFees,
	isDeposit,
	businessId,
	business,
	user,
	contextData,
	shippingFees,
	shippingExplanationSentence,
	isGiftVoucher,
	isOnlineShop,
	isAccount,
	confirm,
	getSpecificPaymentMethod,
	getRedirectUrl,
	newPaymentMethodFromElement,
	makeDefault,
	setError,
	intentID,
	isLoading,
	setIsLoading = noop,
	language,
	totalPriceWithoutFees,
	countryCode,
	paymentMethodType,
	setPaymentMethodType,
	hasAcceptedSaveCard,
	updateCardAcceptance,
	updateSaveCardInIntent,
	isCurrentCardOnSession,
	doesSelectedPaymentMethodNeedConsent,
	isProWithMissingPhone,
	...props
}) => {
	const { allFutureAppointments, fetchUserData } = useUserData();

	const isSelectedPaymentLinkedToActiveAppointments =
		props.selectedPaymentMethod &&
		Object.values(allFutureAppointments).some(
			appointment =>
				appointment.paymentMethod === props.selectedPaymentMethod.id &&
				!appointment.chargeId
		);

	const wasPrepayment = usePrevious(isPrepayment);
	const hasPaymentMethods = paymentMethods && paymentMethods.length > 0;
	const location = useLocation();
	const { t } = useTranslation();

	const { stripe } = useStripeElements();

	const isPaymentIntent = isPrepayment || isGiftVoucher;

	const tokenData = {
		intentID,
		isPro,
		amount,
		appointment,
		user,
		formattedAppointment: formatAppointmentForLambda
			? formatAppointmentForLambda()
			: null,
		userPaysFees,
		userId,
		isGiftVoucher,
		isPrePayment: isPrepayment,
		isDeposit,
		isWidget: process.env.WIDGET,
		isWhiteLabelWebsite: process.env.WHITE_LABEL_WEBSITE,
		businessId,
		business,
		redirectUrlRoot: getRedirectUrlRoot(),
		redirectPathname: getRedirectPathname({ location }),
		shippingFees,
		shippingExplanationSentence,
		steps,
		contextData,
		transactionType: getTransactionType({
			isPrePayment: isPrepayment,
			isDeposit,
			isGiftVoucher,
			isOnlineShop
		}),
		veventToDelete: location?.state?.veventToDelete || null,
		countryCode
	};

	useEffect(() => {
		props.setIsSelectedPaymentLinkedToActiveAppointments(
			isSelectedPaymentLinkedToActiveAppointments
		);
	}, [isSelectedPaymentLinkedToActiveAppointments]);

	useEffect(() => {
		fetchUserData();
	}, []);

	useEffect(() => {
		// If this value is not defined then  don't generate new intent
		if (isPro !== undefined) {
			const intentData = {
				appointment,
				userId,
				isPrePayment: isPrepayment,
				userPaysFees,
				amountWithoutFees: amountWithoutFees || amount,
				formattedSteps,
				isDeposit,
				isGiftVoucher,
				businessId,
				user,
				totalAmount: totalPriceWithoutFees,
				transactionType: getTransactionType({
					isPrePayment: isPrepayment,
					isDeposit,
					isGiftVoucher,
					isOnlineShop
				})
			};
			if (
				!clientSecret ||
				(wasPrepayment !== undefined && wasPrepayment !== isPrepayment)
			) {
				getNewIntent({
					isPaymentIntent,
					amount,
					customerID,
					intentData,
					countryCode
				});
			}
		}
	}, [isPro, clientSecret, isPrepayment]);

	useEffect(() => {
		if (newPaymentMethodFromElement) {
			if (
				hasAcceptedSaveCard &&
				(!hasPaymentMethods ||
					(hasPaymentMethods && !props.defaultPaymentMethod))
			) {
				makeDefault(newPaymentMethodFromElement, countryCode);
			}
			confirm({
				//If it's not a prepayment, intendID is setupIntentId not paymentIntentId
				paymentIntentId: isPrepayment ? intentID : null,
				newPaymentMethodFromElement
			});
		}
	}, [newPaymentMethodFromElement, hasAcceptedSaveCard]);

	const options = {
		clientSecret,
		...stripeElementStyle,
		locale: language
	};

	const displayToggle =
		paymentMethodType === PAYMENT_TYPE_CARD &&
		!isAccount &&
		!displayAddCard &&
		isPaymentIntent &&
		doesSelectedPaymentMethodNeedConsent &&
		!isSelectedPaymentLinkedToActiveAppointments;

	const expiredCards = useMemo(() => {
		const expired = {};
		const now = new Date();
		(paymentMethods || []).forEach(({ card, id }) => {
			const { exp_month, exp_year } = card ?? {};
			if (exp_month && exp_year) {
				const expirationDate = new Date(exp_year, exp_month);
				if (isBefore(expirationDate, now)) {
					expired[id] = true;
				}
			}
		});
		return expired;
	}, [paymentMethods]);

	const showExpiredCardWarning =
		!displayAddCard &&
		paymentMethods &&
		Object.keys(expiredCards).length === paymentMethods.length;

	useEffect(() => {
		if (
			props.defaultPaymentMethod &&
			props.defaultPaymentMethod in expiredCards
		) {
			props.setSelectedPaymentMethod(null);
		}
	}, [
		props.defaultPaymentMethod,
		expiredCards,
		props.setSelectedPaymentMethod
	]);

	useEffect(() => {
		if (paymentMethods?.length === 0 && !displayAddCard)
			setDisplayAddCard(true);
	}, [paymentMethods]);

	if (clientSecret) {
		return (
			<div>
				{hasPaymentMethods && (
					<div className={scssStyles.cardList}>
						{!displayAddCard && (
							<CardsList
								canDefault={canDefault}
								countryCode={countryCode} // countryCode from business locale since this component is used in the business page (book appointment/ c&c /gift voucher)
								defaultPaymentMethod={props.defaultPaymentMethod}
								deleteCard={props.deleteCard}
								expiredCards={expiredCards}
								makeDefault={makeDefault}
								paymentMethodNeededToDelete={props.paymentMethodNeededToDelete}
								paymentMethods={paymentMethods}
								selectedPaymentMethod={props.selectedPaymentMethod}
								setSelectedPaymentMethod={props.setSelectedPaymentMethod}
							/>
						)}
						{showExpiredCardWarning && (
							<div className={styles.noValidCardLeft}>
								<Localize
									args={{ count: paymentMethods.length }}
									text={'bookAppointment.noValidCardLeft'}
								/>
							</div>
						)}
					</div>
				)}
				{displayToggle && (
					<AcceptSaveCardSelector
						hasAcceptedSaveCard={hasAcceptedSaveCard}
						isDisabled={isLoading}
						onChange={hasAccept => updateCardAcceptance(hasAccept)}
					/>
				)}
				{!displayAddCard && (
					<Button
						className={scssStyles.addPaymentMethod}
						iconLeft={'Add'}
						id={'add-payment-method'}
						label={t('onlinePayment.addMoyenPaiement')}
						type={'linked'}
						onClick={() => {
							setDisplayAddCard(true);
						}}
					/>
				)}
				{displayAddCard && (
					//need this Elements for PaymentElement (this one is initiate with clientSecret)
					<Elements options={options} stripe={stripe}>
						<CheckoutForm
							amount={amount}
							businessId={businessId}
							confirm={confirm}
							countryCode={countryCode}
							getRedirectUrl={getRedirectUrl}
							getSpecificPaymentMethod={getSpecificPaymentMethod}
							hasAcceptedSaveCard={hasAcceptedSaveCard}
							hasPaymentMethods={
								isAccount ? paymentMethods?.length > 1 : hasPaymentMethods
							}
							intentID={intentID}
							isAccount={isAccount}
							isCurrentCardOnSession={isCurrentCardOnSession}
							isGiftVoucher={isGiftVoucher}
							isLoading={isLoading}
							isPaymentIntent={isPaymentIntent}
							isProWithMissingPhone={isProWithMissingPhone}
							paymentMethodType={paymentMethodType}
							setDisplayAddCard={setDisplayAddCard}
							setError={setError}
							setIsLoading={setIsLoading}
							setPaymentMethodType={setPaymentMethodType}
							stripe={stripe}
							tokenData={tokenData}
							updateCardAcceptance={updateCardAcceptance}
							updateSaveCardInIntent={updateSaveCardInIntent}
						/>
					</Elements>
				)}
			</div>
		);
	} else {
		return (
			<div className={scssStyles.spinner}>
				<Spinner />
			</div>
		);
	}
};

const CheckoutForm = ({
	isAccount,
	isPaymentIntent,
	isLoading,
	setIsLoading,
	setDisplayAddCard,
	hasPaymentMethods,
	tokenData,
	getRedirectUrl,
	getSpecificPaymentMethod,
	stripe,
	setError,
	countryCode,
	amount,
	paymentMethodType,
	setPaymentMethodType,
	updateCardAcceptance,
	hasAcceptedSaveCard,
	updateSaveCardInIntent,
	isCurrentCardOnSession,
	businessId,
	isProWithMissingPhone
}) => {
	// need useElements here (and not useStripeElements) to get the elements instance linked to Element with options in parent component (not the one from provider)
	const elements = useElements();
	const { t } = useTranslation();
	useStyles(styles);
	const [isStripeReady, setIsStripeReady] = useState(false);
	const [isStripeFormComplete, setIsStripeFormComplete] = useState(false);
	const { setModal } = useModal();

	useEffect(() => {
		if (!isLoading && elements) {
			elements.fetchUpdates().then(response => {
				if (response?.error) {
					console.log({ error: response.error });
				}
			});
		}
	}, [isLoading]);

	useEffect(() => {
		// set payment method type in order to recalculate stripe fees
		const changeHandler = event => {
			if (paymentMethodType !== event.value.type) {
				// value type should be card / klarna / giropay / bancontact
				setPaymentMethodType(event.value.type);
			}
		};
		// https://stripe.com/docs/js/elements_object/get_payment_element
		const paymentElement = elements?.getElement('payment');
		if (paymentElement) {
			// https://stripe.com/docs/js/element/events/on_change?type=paymentElement
			paymentElement.on('change', changeHandler);
			return () => {
				paymentElement.off('change', changeHandler);
			};
		}
	}, [paymentMethodType]);

	useEffect(() => {
		return () => {
			// important: reset payment method type to default at unmount of PaymentElement
			setPaymentMethodType(PAYMENT_TYPE_CARD);
		};
	}, []);

	const isFormFulfilled = useMemo(
		() =>
			paymentMethodType === PAYMENT_TYPE_CARD
				? isStripeFormComplete &&
				  (!isPaymentIntent || hasAcceptedSaveCard !== null)
				: isStripeFormComplete,
		[
			paymentMethodType,
			isPaymentIntent,
			isStripeFormComplete,
			hasAcceptedSaveCard
		]
	);
	const hasInvalidKlarnaUserNameError = error => {
		const type =
			(error &&
				error?.payment_intent?.last_payment_error?.payment_method?.type) ||
			paymentMethodType;
		const name =
			error &&
			error?.payment_intent?.last_payment_error?.payment_method?.billing_details
				?.name;
		// Klarna url with rules respected: https://docs.klarna.com/klarna-payments/before-you-start/data-requirements/customer-data-requirements/#germany,-austria,-switzerland,-netherlands-and-belgium
		const validCharactersRegex = new RegExp(/^[\p{L}\p{M}\d\s\-.'’°ºᵃª]+$/u);
		const hasSpecificCharacters = !validCharactersRegex.test(name);
		const hasOnlyNumbers = !!error?.param;
		const hasError =
			type === PAYMENT_TYPE_KLARNA && (hasSpecificCharacters || hasOnlyNumbers);
		return hasError ? INVALID_NAME_FOR_USER_USING_KLARNA : null;
	};
	const handleSubmit = async event => {
		event.preventDefault();
		const canSubmitPayment =
			!isProWithMissingPhone ||
			(typeof isProWithMissingPhone === 'function' && !isProWithMissingPhone());

		if (!canSubmitPayment) return;
		if (paymentMethodType !== PAYMENT_TYPE_CARD) {
			setModal({
				content: (
					<PaymentWarningModal
						paymentMethodType={paymentMethodType}
						onClick={submitPayment}
					/>
				),
				hasCloseBtn: false,
				disableScrollTop: true
			});
			return;
		}
		return await submitPayment();
	};

	const submitPayment = async () => {
		setIsLoading(true);

		if (!stripe || !elements) {
			setError('defaultError');
			setIsLoading(false);
			return;
		}
		let result, redirectResult;

		if (isPaymentIntent && !isAccount) {
			if (hasAcceptedSaveCard !== isCurrentCardOnSession) {
				try {
					await updateSaveCardInIntent(countryCode, businessId);
				} catch (e) {
					setError('defaultError');
					setIsLoading(false);
					return;
				}
			}
			// payment with redirection like klarna / giropay / bancontact
			if (paymentMethodType !== PAYMENT_TYPE_CARD) {
				try {
					redirectResult = await getRedirectUrl(tokenData);
				} catch (e) {
					console.log('getRedirectUrl error', e);
				}

				if (
					!redirectResult ||
					redirectResult.error ||
					!redirectResult.redirectUrl
				) {
					// default error but needs to be handled in a better way ?
					setError('defaultError');
					setIsLoading(false);
					return;
				}
			}

			const confirmPayload = {
				elements,
				...(redirectResult && {
					confirmParams: {
						return_url: redirectResult.redirectUrl
					}
				}),
				redirect: 'if_required'
			};

			result = await stripe.confirmPayment(confirmPayload);
		} else {
			result = await stripe.confirmSetup({
				elements,
				redirect: 'if_required'
			});
		}
		if (result.error) {
			isStripeError(result)
				? setError(
						hasInvalidKlarnaUserNameError(result?.error) ||
							result.error.decline_code?.toUpperCase() ||
							result.error.code.toUpperCase()
				  )
				: setError('defaultError');
			setIsLoading(false);
			isAccount && alert(t('onlinePayment.lateBookingError'));
		} else {
			await getSpecificPaymentMethod({
				countryCode,
				paymentMethodId: isPaymentIntent
					? result?.paymentIntent?.payment_method
					: result?.setupIntent?.payment_method
			});
		}
	};

	const paymentElementOptions = {
		wallets: {
			applePay: 'never',
			googlePay: 'never'
		},
		terms: {
			bancontact: 'never',
			card: 'never',
			ideal: 'never',
			sepaDebit: 'never',
			sofort: 'never',
			auBecsDebit: 'never',
			usBankAccount: 'never'
		}
	};

	const displayToggle =
		paymentMethodType === PAYMENT_TYPE_CARD &&
		isPaymentIntent &&
		!isAccount &&
		isStripeReady;

	return (
		<form onSubmit={handleSubmit}>
			<PaymentElement
				options={paymentElementOptions}
				onChange={e => setIsStripeFormComplete(e.complete)}
				onReady={() => setIsStripeReady(true)}
			/>
			{displayToggle && (
				<AcceptSaveCardSelector
					hasAcceptedSaveCard={hasAcceptedSaveCard}
					isDisabled={isLoading}
					onChange={hasAccept => updateCardAcceptance(hasAccept)}
				/>
			)}
			{hasPaymentMethods && (
				<Button
					className={classNames.bind(styles)({
						paymentElementGoBackButton: true
					})}
					iconLeft={'ArrowLeft'}
					id={'stripe-payment-back-to-card-list'}
					label={t('stripePayment.returnToPaymentMethods')}
					type={'linked'}
					onClick={() => {
						setDisplayAddCard(false);
					}}
				/>
			)}
			<Button
				className={classNames.bind(styles)({
					paymentElementPayButton: true
				})}
				id={'stripe-payment-submit'}
				isDisabled={!isFormFulfilled}
				isFullMobile
				isFullWidth
				isLoading={isLoading}
				label={t(
					isAccount ? 'bookAppointment.addCard' : 'stripePayment.submit',
					{ price: formatPrice(amount, true) }
				)}
				size={'large'}
			/>
		</form>
	);
};

const getTransactionType = ({
	isPrePayment,
	isDeposit,
	isGiftVoucher,
	isOnlineShop
}) => {
	if (isPrePayment && !isDeposit && !isGiftVoucher && !isOnlineShop) {
		return 'prepayment';
	} else if (isDeposit) {
		return `${isDeposit}Deposit`;
	} else if (isGiftVoucher) {
		return 'giftVoucher';
	} else if (isOnlineShop) {
		return 'onlineShop';
	} else {
		return 'classicOnlinePayment';
	}
};

const getRedirectUrlRoot = () => {
	if (
		typeof window !== 'undefined' &&
		(process.env.WIDGET || process.env.WHITE_LABEL_WEBSITE)
	) {
		if (process.env.WHITE_LABEL_WEBSITE) return window?.location?.origin;
		// if widget the url can contain query string
		// we need all the url to put it in the lambda url query strings
		// and be able to redirect to it in stripeRedirect lambda even if we can't get token data
		let queryStringToKeep = '';
		let pathnameToKeep = window?.location?.pathname;
		if (window?.location?.search?.length > 0) {
			const queryStringParams = window?.location?.search.split('&');
			// because in widget mode we don't clean url, it is possible that the url has tokenData and error query string from previous buying
			// so we remove it from the query string to keep
			const unformattedQueryStringToKeep = queryStringParams.filter(
				param => !(param.includes('tokenData') || param.includes('error'))
			);
			queryStringToKeep = unformattedQueryStringToKeep.join('&');
		}

		if (window?.location?.pathname?.length === 1 && !queryStringToKeep) {
			pathnameToKeep = '';
		}

		return window?.location?.origin + pathnameToKeep + queryStringToKeep;
	} else if (isNativeApp) {
		return process.env.NODE_ENV === 'production'
			? `https://webview.${credentials.HOST}`
			: typeof window !== 'undefined' && window.origin;
	} else {
		return process.env.NODE_ENV === 'production'
			? `https://www.${credentials.HOST}`
			: 'http://localhost:3000';
	}
};

const getRedirectPathname = ({ location }) => {
	if (
		typeof window !== 'undefined' &&
		process.env.WIDGET &&
		!process.env.WHITE_LABEL_WEBSITE
	) {
		return '';
	}
	return location?.pathname;
};

const stripeElementStyle = {
	appearance: {
		labels: 'above',
		variables: {
			colorPrimary: '#625DF5', //--primary-200
			colorText: '#202020', //--grey-700
			colorBackground: '#FFFFFF', //--white
			fontSizeBase: '14px',
			fontWeightMedium: 500, //--medium
			fontWeightNormal: 400, //--regular
			colorDanger: '#F84F4F', //--danger-200
			borderRadius: '8px' //--border-radius-medium
		},
		rules: {
			'.Tab--selected, .Tab--selected:focus': {
				border: 'none',
				backgroundColor: 'var(--colorBackground)'
			},
			'.Label': {
				color: 'var(--colorText)',
				fontWeight: 'var(--fontWeightMedium)',
				fontSize: 'var(--fontSizeBase)',
				lineHeight: '24px'
			},
			'.Input': {
				backgroundColor: 'var(--colorBackground)',
				border: '1px solid #C7CFD7', //--grey-400
				boxShadow: '0 2px 4px -1px rgba(26, 27, 31, 0.05)', //--shadow-light
				borderRadius: 'var(--borderRadius)'
			},
			'.Input:focus': {
				border: '1px solid var(--colorText)',
				boxShadow: '0 0 0 4px #C7CFD7' //--grey-400
			},
			'.Input--invalid': {
				border: '0px solid var(--colorDanger)'
			},
			'.Error': {
				fontWeight: 'var(--fontWeightNormal)',
				fontSize: 'var(--fontSizeBase)',
				lineHeight: '24px'
			}
		}
	}
};
