// @ts-check
import { format, isPast, subHours } from 'date-fns';
import { firebase } from '@planity/datastores';
import {
	computeFees,
	formatPrice,
	sanitizeName,
	totalAppointmentPrice,
	isStripeError,
	getFinalFees,
	IS_DEPOSIT,
	IS_PREPAYMENT,
	PAYMENT_TYPE_CARD
} from '@planity/helpers';
import { withLocalization } from '@planity/localization';
import React, { Component } from 'react';
import { Localize, withStripeElementsConsumer } from '@planity/components';
import credentials from '@planity/credentials';
import { DepositPaymentChoice } from './deposit_payment_choice';
import { withStripeFees } from '../../app_settings/stripeFeesProvider';
import classNames from 'classnames/bind';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './appointment_payment.modules.scss';
import { Banner, Spinner } from '@planity/ui';
import { Confirmation } from '../../book_appointment/confirmation';
import { BookAppointmentTitle, StripePayment } from '@planity/components';
import { Policies } from './policies';

const { ENABLE_USER_PAYS_FEES } = credentials;
const PARTIAL_DEPOSIT = 'partialDeposit';
const FULL_DEPOSIT_AND_PREPAYMENT = 'fullDepositAndPrepayment';
const CLASSIC_ONLINE_PAYMENT = 'classicOnlinePayment';

class OnlinePayment extends Component {
	constructor(props) {
		super(props);

		this.state = {
			user: null,
			cardIsAdding: false,
			stripeError: null,
			displayAddCard: false
		};
	}

	componentDidMount() {
		const { user } = this.props;

		this.setState({
			user: {
				...user,
				email: firebase.auth().currentUser
					? firebase.auth().currentUser.email
					: null
			}
		});

		if (this.props.paymentMethods && this.props.paymentMethods.length === 0) {
			this.setState({ displayAddCard: true });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { paymentMethods, redirectTokenError } = this.props;

		if (!prevProps.redirectTokenError && !!redirectTokenError) {
			this.props.setError(redirectTokenError);
		} else if (!!prevProps.redirectTokenError && !redirectTokenError) {
			this.props.setError(null);
		}

		if (
			!prevProps.paymentMethods &&
			paymentMethods &&
			paymentMethods.length === 0
		) {
			this.setState({ displayAddCard: true });
		}
	}

	render() {
		const {
			appointment,
			onlinePaymentServices,
			business,
			customerID,
			clientSecret,
			formatAppointmentForLambda,
			formattedSteps,
			isGiftVoucher,
			location,
			title,
			paymentContainerRef,
			status,
			payAll,
			paymentMethodIdToDelete,
			setPayAll,
			allStripeFees,
			isOnline,
			completedSteps,
			setError,
			setIsLoading,
			resetPaymentIntent,
			paymentCreatedDateToDelete,
			moveAppointment,
			totalPriceToDelete,
			isPrePaymentToDelete,
			isDark,
			paymentMethodType,
			selectedPaymentMethod,
			doesSelectedPaymentMethodNeedConsent,
			hasAcceptedSaveCard,
			locale,
			isSelectedPaymentLinkedToActiveAppointments
		} = this.props;

		const date = !isGiftVoucher ? appointment.date : new Date();
		if (!date) return null;

		const classes = classNames.bind(styles);

		const past = isPast(
			subHours(date, business.settings.onlinePayment.lateCancellationDelay)
		);

		const userPaysFees = !!business?.settings?.onlinePayment?.userPaysFees;

		const stripeFees = getFinalFees(
			allStripeFees,
			business?.countryCode,
			paymentMethodType
		);

		const {
			totalPrice,
			totalPriceWithoutFees,
			depositPrice,
			depositPriceWithoutFees,
			depositRest,
			depositRateToApply
		} = totalAppointmentPrice(
			onlinePaymentServices,
			userPaysFees && ENABLE_USER_PAYS_FEES,
			stripeFees
		);

		const isPrePayment =
			status === IS_PREPAYMENT ||
			(status === IS_DEPOSIT && (!!payAll || depositRateToApply === 100));
		const hasDepositActivated =
			status === IS_DEPOSIT && depositRateToApply < 100;
		const isDeposit =
			status === IS_DEPOSIT && !payAll && depositRateToApply < 100;
		const isPrepaymentOrDeposit = !!isDeposit || !!isPrePayment;

		const cancelFee = !isGiftVoucher
			? this.calculateCancelFee(isPrepaymentOrDeposit, userPaysFees, stripeFees)
			: null;
		const noShowFee = !isGiftVoucher
			? this.calculateNoShowFee(isPrepaymentOrDeposit, userPaysFees, stripeFees)
			: null;

		const fees = userPaysFees
			? computeFees({
					amount: isDeposit ? depositPriceWithoutFees : totalPriceWithoutFees,
					stripeFees
			  })
			: 0;

		// need to compute price without fees + fees because fees change in deposit mode
		const totalPriceWithFees = formatPrice(totalPriceWithoutFees + fees, true);

		const parsedPrice = price => parseFloat(price.replace(',', '.'));
		const hasSameAmount = isPrePaymentToDelete
			? isDeposit
				? parsedPrice(depositPrice) === parsedPrice(totalPriceToDelete)
				: parsedPrice(totalPrice) === parsedPrice(totalPriceToDelete)
			: false;

		const stripePaymentAmount = this.props.isGiftVoucher
			? this.props.totalPriceInCents
			: (isDeposit ? depositPriceWithoutFees : totalPriceWithoutFees) + fees;

		const stripePaymentAmountWithoutFees = this.props.isGiftVoucher
			? this.props.totalPriceInCents
			: isDeposit
			? depositPriceWithoutFees
			: totalPriceWithoutFees;

		const isVisiblePayment = !isGiftVoucher
			? !!business?.modules?.onlinePayment &&
			  !!business?.settings?.onlinePayment &&
			  isOnline
			: !!business?.settings?.onlinePayment; // online payment can be deactivated but stripe onboarding has been done

		const redirectClientSecret =
			!this.props.restartBooking && location?.state?.clientSecret;

		const isPaymentIntent = isPrepaymentOrDeposit || isGiftVoucher;

		const hasPassedCardConsentRequirement =
			!!selectedPaymentMethod &&
			(!isPaymentIntent || // imprint payment
				!doesSelectedPaymentMethodNeedConsent || // we don't need consent verification -> see the function .does_payment_method_need_consent.js
				isSelectedPaymentLinkedToActiveAppointments || // the card is still linked to future appointments (with imprint)
				hasAcceptedSaveCard !== null); // the user has replied to consent verification

		const isSubmitEnabled =
			(moveAppointment && hasSameAmount) ||
			paymentMethodType !== PAYMENT_TYPE_CARD ||
			hasPassedCardConsentRequirement;

		const renderPaymentConfirmationButton = (
			<div className={classes({ payment: true })}>
				<hr className={styles.simpleHr} />
				<Confirmation
					appointment={appointment}
					completedSteps={completedSteps || []}
					confirm={() => this.confirm(hasSameAmount)}
					depositPrice={depositPrice}
					hasSameAmount={hasSameAmount}
					isDeposit={isDeposit}
					isDisabled={!isSubmitEnabled}
					isGiftVoucher={isGiftVoucher}
					isLoading={this.props.isLoading}
					isOnline={isOnline}
					isPending={this.props.isPending}
					isPrePayment={isPrepaymentOrDeposit}
					isVisiblePayment={isVisiblePayment}
					moveAppointment={this.props.moveAppointment}
					totalPrice={this.props.totalPrice || totalPrice || 0}
					userId={this.props.userId}
					wasBooked={this.props.wasBooked}
				/>
			</div>
		);

		const canDisplayConfirm =
			isGiftVoucher || this.props.canDisplayConfirmOnBooking;

		if (!isVisiblePayment && canDisplayConfirm)
			return renderPaymentConfirmationButton;
		return (
			<div className={classes({ payment: true })} ref={paymentContainerRef}>
				<BookAppointmentTitle
					index={isGiftVoucher ? '5. ' : '4. '}
					isDark={isDark}
					text={title ? title : 'bookAppointment.onlinePaymentTitle'}
				/>
				{this.state.user && customerID ? (
					<div className={`${styles.card} planity_online_payment_wrapper`}>
						{isPrePaymentToDelete && moveAppointment && !hasSameAmount && (
							<div className={styles.errorMessage}>
								<Localize
									args={{
										date: paymentCreatedDateToDelete,
										price: totalPriceToDelete
									}}
									text={'bookAppointment.errors.MOVED_WITH_REFUND'}
								/>
							</div>
						)}
						{/* moveAppointment */}
						{!isPrePaymentToDelete &&
							moveAppointment &&
							!!(isPrePayment || isDeposit) && (
								<div className={styles.errorMessage}>
									<Localize
										args={{
											businessName: business?.name || '',
											price: totalPrice
										}}
										text={
											paymentMethodIdToDelete
												? 'bookAppointment.errors.MOVED_FROM_IMPRINT_TO_PREPAYMENT'
												: 'bookAppointment.errors.MOVED_FROM_NO_ONLINE_PAYMENT_TO_PREPAYMENT'
										}
									/>
								</div>
							)}
						{hasDepositActivated &&
							(!moveAppointment || (moveAppointment && !hasSameAmount)) && (
								<div>
									<DepositPaymentChoice
										depositAmount={depositPrice}
										depositRest={depositRest}
										isLoading={this.props.isLoading || !clientSecret}
										payAll={payAll}
										setPayAll={setPayAll}
										totalAmount={totalPrice}
									/>
									<hr />
								</div>
							)}
						<div className={styles.table}>
							{onlinePaymentServices.isOnline.map(({ name, id, prices }) => {
								if (!prices) return <div key={id} />;
								if (prices) {
									return (
										<div key={id}>
											<span>{`${sanitizeName(name)}`}</span>
											<span className={styles.price}>
												{formatPrice(
													prices.default || prices.min || prices.max || 0,
													true
												)}
											</span>
										</div>
									);
								}
							})}
						</div>
						{!isGiftVoucher && ENABLE_USER_PAYS_FEES && userPaysFees && (
							<div className={styles.table}>
								<div>
									<span>
										<Localize text={'onlinePayment.userPaysFeesLabel'} />
									</span>
									<span className={styles.price}>
										{formatPrice(fees, true)}
									</span>
								</div>
								<hr />
							</div>
						)}
						{isDeposit ? (
							<div className={styles.table}>
								<div className={styles.thead}>
									<span>
										<Localize text={'onlinePayment.total.default'} />
									</span>
									<span className={styles.price}>{totalPriceWithFees}</span>
								</div>
								{moveAppointment && hasSameAmount ? (
									<>
										<div className={styles.thead}>
											<span>
												<Localize
													args={{ date: paymentCreatedDateToDelete }}
													text={'onlinePayment.total.paid'}
												/>
											</span>
											<span>{totalPriceToDelete}</span>
										</div>
										<hr />
										<div className={styles.thead}>
											<span>
												<Localize text={'onlinePayment.total.restToPayLater'} />
											</span>
											<span>{depositRest}</span>
										</div>
									</>
								) : (
									<>
										<hr />
										<div className={styles.thead}>
											<span>
												<Localize text={'onlinePayment.details.depositValue'} />
											</span>
											<span>{depositPrice}</span>
										</div>
										<div>
											<span>
												<Localize text={'onlinePayment.details.onSiteValue'} />
											</span>
											<span>{depositRest}</span>
										</div>
									</>
								)}
								<hr />
							</div>
						) : (
							<div className={styles.table}>
								<div className={styles.thead}>
									<span>
										<Localize
											text={
												moveAppointment && hasSameAmount
													? 'onlinePayment.total.default'
													: 'onlinePayment.total.toPay'
											}
										/>
									</span>
									<span
										className={styles.price}
										id='appointment-payment-total-price-toPay'
									>
										{this.props.totalPrice || totalPrice}
									</span>
								</div>
								{moveAppointment && hasSameAmount && (
									<div className={styles.thead}>
										<span>
											<Localize
												args={{ date: paymentCreatedDateToDelete }}
												text={'onlinePayment.total.paid'}
											/>
										</span>
										<span>{totalPriceToDelete}</span>
									</div>
								)}
								<hr />
								{/*The rest to pay if online appointment moved */}

								{moveAppointment && hasSameAmount ? (
									<>
										<div className={styles.thead}>
											<span>
												<Localize text={'onlinePayment.total.restToPay'} />
											</span>
											<span className={styles.price}>
												{formatPrice(0, true)}
											</span>
										</div>
										<hr />
									</>
								) : null}
							</div>
						)}
						{/*CANCELLATION POLICIES*/}
						{!isGiftVoucher && (
							<div>
								<span className={styles.label}>
									<Localize
										text={
											isPrepaymentOrDeposit
												? 'onlinePayment.cancel.policy.title'
												: 'onlinePayment.conditionsPaiement'
										}
									/>
								</span>
								<div>
									<span className={styles.precision}>
										{/*Only display charge if prePayment option is not available*/}
										{!isPrepaymentOrDeposit && (
											<Localize text={'onlinePayment.chargeEndOfAppointment'} />
										)}
									</span>
								</div>

								{this.policyTextDisplay({
									isPast: past,
									cancelFee,
									noShowFee,
									totalPriceWithoutFees,
									isPrePayment,
									isDeposit,
									date
								})}
								{(!moveAppointment || (moveAppointment && !hasSameAmount)) && (
									<hr />
								)}
							</div>
						)}
						{/* GIROPAY WARNING */}
						{/* remove it en 2024 : PLAN-14741 */}
						{business.countryCode === 'DE' && (
							<div>
								<Banner iconSize={20} status='INFORMATION_WARNING'>
									<p style={{ fontSize: 14, lineHeight: '18px' }}>
										<Localize text='bookAppointment.appointment.endGiropay.title' />
									</p>
									<p style={{ fontSize: 12, lineHeight: '16px' }}>
										<Localize text='bookAppointment.appointment.endGiropay.content' />
									</p>
								</Banner>
							</div>
						)}
						{(!moveAppointment || (moveAppointment && !hasSameAmount)) && (
							<StripePayment
								{...this.props}
								amount={stripePaymentAmount}
								amountWithoutFees={stripePaymentAmountWithoutFees}
								clientSecret={redirectClientSecret || clientSecret}
								confirm={
									isGiftVoucher
										? this.props.confirmGiftVoucher
										: this.props.confirm
								}
								countryCode={business.countryCode}
								displayAddCard={this.state.displayAddCard}
								formatAppointmentForLambda={formatAppointmentForLambda}
								formattedSteps={formattedSteps}
								isDeposit={
									hasDepositActivated ? (payAll ? 'full' : 'partial') : null
								}
								isLoading={this.props.isLoading}
								isPrePayment={isPrePayment || isGiftVoucher}
								resetPaymentData={this.props.resetPaymentData}
								resetPaymentIntent={resetPaymentIntent}
								setDisplayAddCard={displayAddCard => {
									this.setState({ displayAddCard });
								}}
								setError={setError}
								setIsLoading={setIsLoading}
								steps={JSON.stringify(onlinePaymentServices)}
								totalPrice={this.props.totalPrice || totalPrice}
								totalPriceWithoutFees={totalPriceWithoutFees}
								userPaysFees={userPaysFees}
							/>
						)}
						{canDisplayConfirm &&
							((moveAppointment && hasSameAmount) ||
								(!this.state.displayAddCard && clientSecret)) &&
							renderPaymentConfirmationButton}
						{paymentMethodType === PAYMENT_TYPE_CARD && (
							<Policies isGiftVoucher={isGiftVoucher} locale={locale} />
						)}
					</div>
				) : (
					<div className={styles.spinner}>
						<Spinner />
					</div>
				)}
			</div>
		);
	}

	policyTextDisplay = ({
		isPast,
		cancelFee,
		noShowFee,
		totalPriceWithoutFees,
		isPrePayment,
		isDeposit,
		date
	}) => {
		const { business, dateLocale } = this.props;
		// date-fns doesn't hande 'null' value so send default date in case of date is null
		const dateForFormat = date || new Date();
		const formattedDate = format(
			subHours(
				dateForFormat,
				business.settings.onlinePayment.lateCancellationDelay
			),
			'dd MMMM',
			{ locale: dateLocale }
		);
		const formattedHours = format(
			subHours(
				dateForFormat,
				business.settings.onlinePayment.lateCancellationDelay
			),
			'HH:mm',
			{ locale: dateLocale }
		);
		const tradKeyword = isPrePayment
			? FULL_DEPOSIT_AND_PREPAYMENT
			: isDeposit
			? PARTIAL_DEPOSIT
			: CLASSIC_ONLINE_PAYMENT;

		const displayedCancelFees = formatPrice(
			(isPrePayment || isDeposit
				? totalPriceWithoutFees / 100 - cancelFee
				: cancelFee) * 100,
			true
		);

		const displayedNoShowFees = formatPrice(
			(isPrePayment || isDeposit
				? totalPriceWithoutFees / 100 - noShowFee
				: noShowFee) * 100,
			true
		);

		return (
			<div>
				{isPast && cancelFee > 0 && (
					<div
						className={styles.precision}
						id={`onlinePayment.lateCancellation.${tradKeyword}`}
					>
						<Localize
							args={{ amount: displayedCancelFees }}
							text={`onlinePayment.lateCancellation.${tradKeyword}`}
						/>
					</div>
				)}
				{!isPast && cancelFee > 0 && (
					<div
						className={styles.precision}
						id={`onlinePayment.cancellation.${tradKeyword}`}
					>
						<Localize
							args={{
								date: formattedDate,
								hour: formattedHours,
								amount: displayedCancelFees
							}}
							text={`onlinePayment.cancellation.${tradKeyword}`}
						/>
					</div>
				)}
				{(isPrePayment || isDeposit) && cancelFee === 0 && (
					<div
						className={styles.precision}
						id={'onlinePayment.cancel.policy.cancelRateNull'}
					>
						<Localize text={'onlinePayment.cancel.policy.cancelRateNull'} />
					</div>
				)}
				{!isPrePayment && !isDeposit && cancelFee === 0 && (
					<div
						className={styles.precision}
						id={'onlinePayment.annulation.cancelRateNull'}
					>
						<Localize text={'onlinePayment.annulation.cancelRateNull'} />
					</div>
				)}
				{noShowFee > 0 && (
					<div
						className={styles.precision}
						id={`onlinePayment.noShow.${tradKeyword}`}
					>
						<Localize
							args={{ amount: displayedNoShowFees }}
							text={`onlinePayment.noShow.${tradKeyword}`}
						/>
					</div>
				)}
			</div>
		);
	};

	calculateCancelFee = (isPrePayment, userPaysFees, stripeFees) => {
		const { onlinePaymentServices } = this.props;
		const cancelFee =
			onlinePaymentServices.isOnline
				.concat(onlinePaymentServices.remaining)
				.reduce((prix, service) => {
					let price = 0;
					if (service.prices && service.prices.default !== 0) {
						price = service.prices.default
							? service.prices.default
							: service.prices.min;
					}

					if (price) {
						return prix + (price * service.cancelRate) / 100;
					}

					return prix;
				}, 0) / 100;
		const fees =
			computeFees({
				amount: cancelFee * 100,
				stripeFees
			}) / 100;

		return userPaysFees && ENABLE_USER_PAYS_FEES && !isPrePayment
			? cancelFee + fees
			: cancelFee;
	};

	calculateNoShowFee = (isPrePayment, userPaysFees, stripeFees) => {
		const { onlinePaymentServices } = this.props;
		const noShowFee =
			onlinePaymentServices.isOnline
				.concat(onlinePaymentServices.remaining)
				.reduce((prix, service) => {
					let price = 0;
					if (service.prices && service.prices.default !== 0) {
						price = service.prices.default
							? service.prices.default
							: service.prices.min;
					}

					if (price) {
						return prix + (price * service.noshowRate) / 100;
					}

					return prix;
				}, 0) / 100;
		const fees =
			computeFees({
				amount: noShowFee * 100,
				stripeFees
			}) / 100;

		return userPaysFees && ENABLE_USER_PAYS_FEES && !isPrePayment
			? noShowFee + fees
			: noShowFee;
	};

	confirm = async hasSameAmount => {
		const {
			isGiftVoucher,
			stripe,
			confirmGiftVoucher,
			confirm: confirmAppointment,
			selectedPaymentMethod,
			isPrepayment,
			clientSecret,
			setError,
			setIsLoading,
			moveAppointment,
			stripeCountryCode,
			userId,
			updateConsentPaymentMethod,
			doesSelectedPaymentMethodNeedConsent,
			isSelectedPaymentLinkedToActiveAppointments,
			paymentMethodType
		} = this.props;
		if (!stripe) {
			//TODO good error translation
			return setError('defaultError');
		}

		setIsLoading(true);

		let result;
		if (!moveAppointment || (moveAppointment && !hasSameAmount)) {
			//cas empreinte
			if (!isGiftVoucher && !isPrepayment) {
				result = await stripe.confirmCardSetup(clientSecret, {
					payment_method: selectedPaymentMethod.id
				});
				if (result.error) {
					setIsLoading(false);
					return isStripeError(result)
						? setError(
								result.error.decline_code?.toUpperCase() ||
									result.error.code.toUpperCase()
						  )
						: setError('defaultError');
				}
				return confirmAppointment();
			}

			//cas paymentIntent (pré paiement ou cc)
			result = await stripe.confirmCardPayment(clientSecret, {
				payment_method: selectedPaymentMethod.id
			});

			if (result.error) {
				setIsLoading(false);
				return isStripeError(result)
					? setError(
							result.error.decline_code?.toUpperCase() ||
								result.error.code.toUpperCase()
					  )
					: setError('defaultError');
			}

			if (
				paymentMethodType === PAYMENT_TYPE_CARD &&
				doesSelectedPaymentMethodNeedConsent &&
				!isSelectedPaymentLinkedToActiveAppointments
			) {
				updateConsentPaymentMethod(stripeCountryCode, userId);
			}
		}

		if (isGiftVoucher) {
			return confirmGiftVoucher({
				paymentIntentId: result?.paymentIntent?.id || null
			});
		}

		if (isPrepayment) {
			return confirmAppointment({
				paymentIntentId: result?.paymentIntent?.id || null,
				hasSameAmount
			});
		}
	};
}

export default withStripeElementsConsumer(
	withStripeFees(withLocalization(withStyles(styles)(OnlinePayment)))
);
