import { useAuth } from '@planity/components';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { BookAppointmentTitle } from '@planity/components';
import styles from './appointment_user.module.scss';
import { SignedIn } from './signed_in';
import { SignedOut } from './signed_out';

export const AppointmentUser = ({
	appointment,
	business,
	conditionsLink,
	isGiftVoucher,
	isPending,
	onSignOut,
	onSignUp,
	setHasConfirmableUser,
	style,
	title,
	userContainerRef,
	isPrePaymentToDelete,
	moveAppointment,
	paymentCreatedDateToDelete,
	totalPriceToDelete,
	totalPrice,
	isOnline,
	isDark,
	phoneError
}) => {
	useStyles(styles);
	const { isSignedIn, isVerified, user, userId, isPro } = useAuth();

	const date = appointment?.date;
	const hasConfirmableUser = appointment?.hasConfirmableUser;

	const classes = classNames.bind(styles);
	if (!date) {
		if (!isGiftVoucher) {
			return null;
		}
		return (
			<div ref={userContainerRef}>
				<BookAppointmentTitle
					index={title?.index || '4.'}
					isDark={isDark}
					text={title?.title || 'bookAppointment.giftUserTitle'}
				/>
				{isSignedIn ? (
					<SignedIn
						authBooking
						hasConfirmableUser={hasConfirmableUser}
						hasPhoneError={phoneError}
						isOnline={isOnline}
						isPending={isPending}
						isPrePaymentToDelete={isPrePaymentToDelete}
						isPro={isPro}
						isVerified={isVerified}
						moveAppointment={moveAppointment}
						paymentCreatedDateToDelete={paymentCreatedDateToDelete}
						setHasConfirmableUser={setHasConfirmableUser}
						totalPrice={totalPrice}
						totalPriceToDelete={totalPriceToDelete}
						user={user}
						userId={userId}
						onSignOut={onSignOut}
					/>
				) : (
					<SignedOut
						authBooking
						business={business}
						conditionsLink={conditionsLink}
						style={style}
						user={user}
						userId={userId}
						onBooking
						onSignUp={onSignUp}
					/>
				)}
			</div>
		);
	}
	return (
		<div className={classes({ appointmentUser: true })}>
			<BookAppointmentTitle
				index={'3.'}
				isDark={isDark}
				text={title || 'bookAppointment.userTitle'}
			/>
			{isSignedIn ? (
				<SignedIn
					authBooking
					hasPhoneError={phoneError}
					isOnline={isOnline}
					isPending={isPending}
					isPrePaymentToDelete={isPrePaymentToDelete}
					isPro={isPro}
					isVerified={isVerified}
					moveAppointment={moveAppointment}
					paymentCreatedDateToDelete={paymentCreatedDateToDelete}
					setHasConfirmableUser={setHasConfirmableUser}
					totalPrice={totalPrice}
					totalPriceToDelete={totalPriceToDelete}
					user={user}
					userId={userId}
				/>
			) : (
				<SignedOut
					authBooking
					business={business}
					conditionsLink={conditionsLink}
					defaultCountry={(business && business.countryCode) || 'FR'}
					style={style}
					user={user}
					userId={userId}
					onBooking
					onSignUp={onSignUp}
				/>
			)}
		</div>
	);
};
