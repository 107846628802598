// @ts-check
import React from 'react';
import { Localize } from '@planity/components';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './deposit_payment.modules.scss';
import { useTranslation } from '@planity/localization';
import { Radio } from '@planity/ui';
import { noop } from '@planity/helpers';

export const DepositPaymentChoice = ({
	totalAmount,
	depositRest,
	payAll,
	setPayAll,
	depositAmount,
	isLoading
}) => {
	const { t } = useTranslation();
	useStyles(styles);
	const classes = classNames.bind(styles)({
		depositPayment: true
	});
	const onChange = () => setPayAll(!payAll);
	return (
		<div className={classes}>
			<span className={styles.title}>
				<Localize text={'onlinePayment.how.title'} />
			</span>
			<div className={styles.choices}>
				<Radio
					isChecked={!payAll}
					label={t('onlinePayment.how.depositLabel')}
					labelClassName={styles.label}
					name={'partial-payment'}
					text={() => (
						<Localize
							args={{
								depositAmount,
								leftToPayAmount: depositRest
							}}
							text={'onlinePayment.how.depositText'}
						/>
					)}
					textClassName={styles.precision}
					onChange={isLoading ? noop : onChange}
				/>
				<Radio
					isChecked={payAll}
					label={t('onlinePayment.how.allLabel')}
					labelClassName={styles.label}
					name={'full-payment'}
					text={() => (
						<Localize
							args={{ totalAmount }}
							text={'onlinePayment.how.allText'}
						/>
					)}
					textClassName={styles.precision}
					onChange={isLoading ? noop : onChange}
				/>
			</div>
		</div>
	);
};
