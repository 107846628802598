export const IS_PREPAYMENT = 'isPrepayment';
export const IS_DEPOSIT = 'isDeposit';
export const IS_ONLINE_PAYMENT = 'isOnlinePayment';
export const NO_ONLINE_PAYMENT = 'noOnlinePayment';

/**
 * Give the kind of online payment options the business uses: prepayment, deposit...
 * @param business {Object} the business, as it is in firebase
 * @return {"isPrepayment"|"isDeposit"|"isOnlinePayment"|"noOnlinePayment"}
 */
export const businessOnlinePaymentStatus = business => {
	if (!business?.settings?.onlinePayment) return NO_ONLINE_PAYMENT;
	const { prePayment, isDeposit, onlinePayment } =
		business.settings.onlinePayment;

	if (prePayment && isDeposit) return IS_DEPOSIT;
	if (prePayment) return IS_PREPAYMENT;
	if (onlinePayment) return IS_ONLINE_PAYMENT;
	return NO_ONLINE_PAYMENT;
};
