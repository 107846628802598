import React, { Component } from 'react';
import { formatPhoneNumber } from '@planity/helpers';
import UserProfileForm from '../../user_profile_form';
import { PhoneVerificationForm } from '../../phone_verification_form';
import { Button } from '@planity/ui';
import { withTranslation } from '@planity/localization';
import withStyles from 'isomorphic-style-loader/withStyles';
import classNames from 'classnames/bind';
import styles from './signed_in.module.scss';
import { Localize } from '../../localize';

export const SignedIn = withStyles(styles)(
	withTranslation()(
		class extends Component {
			state = {
				isEditingProfile: false
			};

			static getDerivedStateFromProps(props, state) {
				if (props.hasPhoneError && !state.isEditingProfile) {
					return {
						isEditingProfile: true,
						hasEditedProfile: true
					};
				}
				return null;
			}
			componentDidMount() {
				this.syncConfirmableState();
			}

			componentDidUpdate() {
				this.syncConfirmableState();
			}

			componentWillUnmount() {
				this.syncConfirmableState(false);
			}

			render() {
				const {
					user,
					userId,
					isVerified,
					isPro,
					isPending,
					onSignOut,
					t,
					isPrePaymentToDelete,
					moveAppointment,
					paymentCreatedDateToDelete,
					totalPriceToDelete,
					totalPrice,
					isOnline,
					hasPhoneError
				} = this.props;
				const classes = classNames.bind(styles)({
					user: true
				});
				const { isEditingProfile } = this.state;

				const phoneNumber = user.phone && formatPhoneNumber(user.phone);

				const userProfile = (
					<div className={styles.info}>
						<span className={styles.name}>{user.name}</span>
						{phoneNumber && <span className={styles.phone}>{phoneNumber}</span>}
						{!isVerified && (
							<span>{` ${t('bookAppointment.userProfile.notVerified')}`}</span>
						)}
					</div>
				);

				return (
					<div className={classes}>
						{isEditingProfile ? (
							<div className={styles.card}>
								<UserProfileForm
									isPro={isPro}
									isVerified={isVerified}
									requestPhoneError={hasPhoneError}
									user={user}
									userId={userId}
									onBooking
									onCancel={this.exitEditMode}
									onSignOut={onSignOut}
									onSuccess={this.exitEditMode}
								/>
							</div>
						) : (
							<div
								className={`${styles.card} ${styles.logged} planity_appointment_user`}
							>
								<div className={styles.description}>
									{userProfile}
									{!isEditingProfile && (
										<Button
											isLoading={isPending}
											label={t('common.edit')}
											type={'underlined'}
											onClick={() =>
												this.setState({
													isEditingProfile: true,
													hasEditedProfile: true
												})
											}
										/>
									)}
									{(isVerified === null || isVerified === false) && (
										<PhoneVerificationForm
											sendSMSOnMount={!this.state.hasEditedProfile}
										/>
									)}
								</div>
								{!isOnline && isPrePaymentToDelete && moveAppointment && (
									<div className={styles.errorMessage}>
										{!totalPrice ? (
											<Localize
												args={{
													date: paymentCreatedDateToDelete,
													priceToDelete: totalPriceToDelete
												}}
												text={'bookAppointment.errors.MOVED_TO_DEVIS'}
											/>
										) : (
											<Localize
												args={{
													date: paymentCreatedDateToDelete,
													priceToDelete: totalPriceToDelete,
													price: totalPrice
												}}
												text={
													'bookAppointment.errors.MOVED_TO_NO_ONLINE_PAYMENT'
												}
											/>
										)}
									</div>
								)}
							</div>
						)}
					</div>
				);
			}

			exitEditMode = () => {
				this.setState({
					isEditingProfile: false
				});
			};

			syncConfirmableState(state) {
				const { hasConfirmableUser, setHasConfirmableUser, isVerified } =
					this.props;
				const { isEditingProfile } = this.state;

				const isConfirmable =
					state === undefined ? !!isVerified && !isEditingProfile : state;
				if (hasConfirmableUser !== isConfirmable) {
					setHasConfirmableUser(isConfirmable);
				}
			}
		}
	)
);
