export function PhoneIcon({ outlined }) {
	return (
		<svg
			width='25'
			height='24'
			viewBox='0 0 25 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M9.09281 4.75H6.26813C5.54012 4.75 4.94995 5.34017 4.94995 6.06818C4.94995 13.3483 10.8517 19.25 18.1318 19.25C18.8598 19.25 19.45 18.6598 19.45 17.9318V15.1071L16.3428 13.0357L14.7317 14.6468C14.4518 14.9267 14.0337 15.0137 13.6821 14.8321C13.0857 14.524 12.1181 13.9452 11.1642 13.0357C10.1876 12.1045 9.61544 11.1011 9.32824 10.494C9.16729 10.1537 9.26047 9.76091 9.52664 9.49474L11.1642 7.85714L9.09281 4.75Z'
				stroke={!outlined ? 'currentColor' : 'none'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
