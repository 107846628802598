import { getDateFromString } from '@planity/helpers';
import { getTime, parseISO } from 'date-fns';

export function getNewestBeautyTeam(userQueries) {
	if (Object.keys(userQueries || {}).length === 0) return [];
	return Object.keys(userQueries || {})
		.reduce((all, businessId) => {
			const vevents = userQueries[businessId].vevents;
			if (Object.keys(vevents || {}).length > 0) {
				const sortedVevents = Object.keys(vevents || {}).sort((idX, idY) => {
					const x = vevents[idX];
					const y = vevents[idY];
					const xStart = getDateFromString(x.start);
					const yStart = getDateFromString(y.start);
					return yStart - xStart;
				});
				all.push({
					...userQueries[businessId].business,
					businessId,
					latestVevent: vevents[sortedVevents[0]].start
				});
			}
			return all;
		}, [])
		.sort((x, y) => {
			return (
				getTime(parseISO(y.latestVevent)) - getTime(parseISO(x.latestVevent))
			);
		});
}
