import React, { useEffect, useRef, useState } from 'react';
import { withResponsive } from '@planity/theme';
import { firebase } from '@planity/datastores';
import {
	Localize,
	signOut,
	useAuth,
	useErrorMessages
} from '@planity/components';
import PasswordChangeForm from '../password_change_form';
import UserProfileForm from '../user_profile_form';
import OnlinePayment from './onlinePayment';
import { OnlinePaymentProvider } from '../business_booking/online_payment/providerComponent';
import { SocialAccountsLink } from './social_accounts_link';
import { PhoneVerificationForm } from '../phone_verification_form';
import { Invoices } from './invoices';
import { Card, Button } from '@planity/ui';
import { useUikit } from '@planity/ui/shared/useUikit';
import styles from './my_profile.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { isNativeApp } from '@planity/webview';
import { Modal } from '@planity/ui';
import {
	useLocalization,
	useLocalizedRoutes,
	useTranslation
} from '@planity/localization';
import { invokeLambda } from '@planity/helpers/browser';
import { useHistory } from 'react-router-dom';

export const MyProfile = withResponsive(
	({ mode, user, userId, responsive, isDark, ...props }) => {
		useStyles(styles);
		const { t } = useTranslation();
		const { locale } = useLocalization();
		const { isPro, isVerified } = useAuth();
		const { pushMessage } = useErrorMessages();
		const { routes } = useLocalizedRoutes();
		const history = useHistory();

		const [showPasswordValidation, setShowPasswordValidation] = useState(false);
		const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);
		const [deleteAccountLoading, setDeleteAccountLoading] = useState(false);
		const { toggle, toggleIcon } = useUikit();
		// This is not realtime so we have to use state
		const [providers, setProviders] = useState(
			firebase.auth().currentUser &&
				firebase
					.auth()
					.currentUser.providerData.map(({ providerId }) => providerId)
		);

		// Trick so the effect doesn't trigger onMount.
		const isInitialMount = useRef(true);
		useEffect(() => {
			if (isInitialMount.current) isInitialMount.current = false;
			else setShowPasswordValidation(true);
		}, [user.phone]);

		const signOutCallback = async () => {
			await signOut();
			// if the user is on the path myAccount/review-key and decides to log out,
			// we have to clear the path because if the user tries to log back in with another account,
			// the review-key will log him out of his account again to reconnect it to the one of the review-key
			history.replace(routes.myAccount);
			window.scrollTo({ top: 0, behavior: 'smooth' });
		};
		const deleteAccountCallback = async () => {
			const firebaseToken = await firebase.auth().currentUser.getIdToken();
			setDeleteAccountLoading(true);
			const res = await invokeLambda('deleteUserAccount', {
				firebaseToken,
				userId,
				locale
			});
			setDeleteAccountLoading(false);
			if (res?.errorMessage) {
				return pushMessage({
					message: 'webview.errors.CAN_NOT_DELETE_USER',
					type: 'error'
				});
			}
			await signOut();
		};
		return (
			<div>
				<div className={styles.block}>
					<Card
						hasTitleInside
						id='my-contact-information-title'
						title={
							<Localize
								text={`myAccount.myProfile.title.myContactInformation`}
							/>
						}
					>
						<UserProfileForm
							actionsVisibleOnEdit
							isPro={isPro}
							isVerified={isVerified}
							showCancelButton={false}
							toggle={toggle}
							toggleIcon={toggleIcon}
							user={user}
							userId={userId}
						/>
						{!!showPasswordValidation &&
							(isVerified === null || isVerified === false) && (
								<PhoneVerificationForm phoneNumber={user.phone} />
							)}
					</Card>
				</div>

				<OnlinePaymentProvider>
					{/*Spreading props........*/}
					<Card
						hasTitleInside
						id='profile-saved-card-title'
						title={<Localize text={`myAccount.myProfile.title.SAVED_CARD`} />}
					>
						<OnlinePayment
							{...{
								mode,
								user,
								userId,
								isPro,
								responsive,
								isVerified,
								isDark,
								...props
							}}
							styleClass={styles.block}
						/>
					</Card>
				</OnlinePaymentProvider>

				<div className={styles.block}>
					<SocialAccountsLink
						providers={providers}
						setProviders={setProviders}
					/>
				</div>

				<div className={styles.block}>
					<PasswordChangeForm
						isDark={isDark}
						providers={providers}
						setProviders={setProviders}
						toggle={toggle}
						toggleIcon={toggleIcon}
						user={user}
						userId={userId}
					/>
				</div>

				<Invoices />

				<div className={styles.signOut}>
					<Button
						id='profile-signOut-button'
						isFullMobile
						isFullWidth
						label={<Localize text={'myAccount.myProfile.signOut'} />}
						type='danger'
						onClick={signOutCallback}
					/>
				</div>

				{isNativeApp && (
					<div className={styles.deleteAccount}>
						<Button
							id='manage-cookies-button'
							isFullMobile
							isFullWidth
							label={t('footer.cookiesPolicy')}
							type='secondary'
							onClick={() => {
								// Didomi is loaded in html <script> tag
								// eslint-disable-next-line no-undef
								Didomi.preferences.show();
							}}
						/>
						<Button
							id='profile-confidentiality-button'
							isFullMobile
							isFullWidth
							label={t('myAccount.myProfile.confidentialityButton')}
							type='secondary'
							onClick={() => history.push(routes.privacyPolicy)}
						/>
						<Button
							id='profile-deleteAccount-button'
							isFullMobile
							isFullWidth
							label={t('myAccount.myProfile.deleteAccountButton')}
							type='secondary'
							onClick={() => setDeleteAccountModalOpen(true)}
						/>
						<Modal
							className={styles.deleteAccountModal}
							closeModal={() => setDeleteAccountModalOpen(false)}
							hasCloseBtn
							isOpen={deleteAccountModalOpen}
						>
							<div className={styles.deleteAccountModalContainer}>
								<p className={styles.deleteAccountModalTitle}>
									{t('myAccount.myProfile.deleteAccountModal.title')}
								</p>
								<p className={styles.deleteAccountModalText}>
									{t('myAccount.myProfile.deleteAccountModal.text')}
									<a
										className={styles.deleteAccountModalContactMail}
										href={`mailto:${t(
											'myAccount.myProfile.deleteAccountModal.planityContactMail'
										)}`}
									>
										{t(
											'myAccount.myProfile.deleteAccountModal.planityContactMail'
										)}
									</a>
								</p>
								<div className={styles.deleteAccountModalButtonWrapper}>
									<Button
										id='profile-deleteAccount-button'
										isFullMobile
										isFullWidth
										isLoading={deleteAccountLoading}
										label={t(
											'myAccount.myProfile.deleteAccountModal.definitivelyDeleteButton'
										)}
										type='danger'
										onClick={deleteAccountCallback}
									/>
									<Button
										id='profile-deleteAccount-button'
										isFullMobile
										isFullWidth
										label={t(
											'myAccount.myProfile.deleteAccountModal.cancelButton'
										)}
										type='secondary'
										onClick={() => setDeleteAccountModalOpen(false)}
									/>
								</div>
							</div>
						</Modal>
					</div>
				)}

				{process.env.BROWSER && window.RNVersion && (
					<div className={styles.version}>{window.RNVersion}</div>
				)}
			</div>
		);
	}
);
