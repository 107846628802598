import useStyles from 'isomorphic-style-loader/useStyles';
import { useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './checkbox.module.scss';
import { noop } from '@planity/helpers';

export function Checkbox({
	name,
	label,
	type = 'checkbox',
	isDisabled,
	checked,
	onChange = noop,
	errorMessage = false
}) {
	useStyles(styles);
	const input = useRef();

	const classes = classNames.bind(styles)({
		checkbox: true,
		checked: checked,
		disabled: isDisabled,
		isInvalid: errorMessage,
		errorMessage: errorMessage
	});
	// Use of HTML inputs for default behaviour with radio button

	return (
		<div className={classes}>
			<label id='label-input-checkbox-field' className={styles.labelInput}>
				<input
					id='input-checkbox'
					ref={input}
					type={type}
					name={name}
					disabled={isDisabled}
					checked={checked}
					onChange={onChange}
				/>
				<span
					id='input-chckbox'
					className={`${styles.field} planity_auth_input_cgu_box`}
					tabIndex={isDisabled ? -1 : 1}
					onMouseDown={e => e.preventDefault()} // Prevent focus state on click
					onKeyDown={e => (e.code === '32' ? input.current.click() : null)} // Set input checked state
				>
					<svg
						className={styles.icon}
						width='8'
						height='6'
						viewBox='0 0 8 6'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M0.75 3L3 5.25L7.25 0.75'
							stroke='currentColor'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				</span>
				{label && <span className={styles.label}>{label}</span>}
			</label>

			{!isDisabled && errorMessage && (
				<span id='error-message-checkbox' className={styles.errorMessage}>
					{errorMessage}
				</span>
			)}
		</div>
	);
}
