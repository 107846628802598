import React, { useContext, useMemo } from 'react';
import { ResponsiveContext, breakpoints } from '@planity/theme';
import { SignedIn } from './signed_in';
import SignedOut from './signed_out';
import { WithKeyAuth } from './with_key_auth';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './signed_out.module.scss';
import { useAuth } from '../authentication';
import { useLocalizedRoutes } from '@planity/localization';
import {
	MY_APPOINTMENTS_TAB,
	MY_GIFT_VOUCHERS_TAB,
	MY_ORDERS_TAB,
	MY_PERSONAL_DATA_TAB,
	removeLocaleFromPathname
} from '@planity/helpers';
import { Spinner } from '@planity/ui';

export function MyAccountGate({
	linkToBusiness,
	linkToEshop,
	linkToClickAndCollect,
	reviewKey,
	customBookAnAppointment,
	linkToBookAppointment,
	history
}) {
	const { routes } = useLocalizedRoutes();
	const stylesWrapper = createStyle();
	useStyles(styles);
	const classes = classNames.bind(styles)({
		authForm: true
	});

	const { isSignedIn, isVerified, user, userId } = useAuth();
	const selectedTab = useMemo(() => {
		// handle non-localized routes for multipage minisite
		switch (history?.location?.pathname) {
			case routes.myAccountOrders:
			case removeLocaleFromPathname(routes.myAccountOrders):
				return MY_ORDERS_TAB;

			case routes.myAccountGiftCards:
			case removeLocaleFromPathname(routes.myAccountGiftCards):
				return MY_GIFT_VOUCHERS_TAB;

			case routes.myAccountInformation:
			case removeLocaleFromPathname(routes.myAccountInformation):
				return MY_PERSONAL_DATA_TAB;

			default:
				return MY_APPOINTMENTS_TAB;
		}
	}, [history?.location?.pathname]);
	return (
		<div className={classes}>
			<WithKeyAuth reviewKey={reviewKey} userId={userId}>
				{({ appointment, appointmentId, reviewDateHasPassed, isLoading }) =>
					isSignedIn ? (
						<div
							className={'planity_ui_account_background'}
							css={stylesWrapper}
						>
							<SignedIn
								appointment={appointment}
								appointmentId={appointmentId}
								customBookAnAppointment={customBookAnAppointment}
								hasReviewError={reviewDateHasPassed}
								linkToBookAppointment={linkToBookAppointment}
								linkToBusiness={linkToBusiness}
								linkToClickAndCollect={linkToClickAndCollect}
								linkToEshop={linkToEshop}
								reviewDataIsLoading={isLoading}
								selectedTab={selectedTab}
							/>
						</div>
					) : isLoading ? (
						<div className={styles.spinner}>
							<Spinner />
						</div>
					) : (
						isSignedIn === false && (
							<div
								className={`${styles.signedOut} planity_ui_account_background`}
							>
								<SignedOut
									hasReviewError={reviewDateHasPassed}
									isVerified={isVerified}
									user={user}
									userId={userId}
								/>
							</div>
						)
					)
				}
			</WithKeyAuth>
		</div>
	);
}

function createStyle() {
	if (process.env.WIDGET) {
		const { isTablet, isDesktop } = useContext(ResponsiveContext);
		return [
			{
				display: 'flex',
				margin: '0 auto 10px auto',
				flexDirection: 'column',
				minHeight: '95vh'
			},
			isTablet &&
				{
					// margin: '0 15px 10px 15px'
				},
			isDesktop && {
				maxWidth: 1170
				// margin: '0 auto 10px auto'
				// 	paddingLeft: 15,
				// 	paddingRight: 15
			}
		];
	} else {
		return {
			display: 'flex',
			margin: '0 auto 10px auto',
			flexDirection: 'column',
			minHeight: '95vh',
			[breakpoints.desktopQuery]: {
				margin: '0 auto 10px auto'
			}
		};
	}
}
