import { computeFees, formatPrice } from './price';

export function totalAppointmentPrice(
	onlinePaymentServices,
	includeFees,
	stripeFees = {}
) {
	//get max value of all online payment services' deposit rate
	const depositRateToApply = Math.max.apply(
		null,
		onlinePaymentServices.isOnline.map(
			service => (service.onlinePayment && service.cancelRate) || 0
		)
	);
	const { totalPrice, _depositPrice } = onlinePaymentServices.isOnline.reduce(
		(all, service) => {
			const { prices } = service;
			if (prices) {
				all._depositPrice +=
					(prices.default || prices.min || prices.max || 0) *
					depositRateToApply;

				all.totalPrice += prices.default || prices.min || prices.max || 0;
			}
			return all;
		},
		{ totalPrice: 0, _depositPrice: 0 }
	);

	const depositPrice = _depositPrice / 100;

	if (includeFees) {
		return {
			totalPrice: formatPrice(
				totalPrice +
					computeFees({
						amount: totalPrice,
						stripeFees
					}),
				true
			),
			totalPriceWithoutFees: Math.round(totalPrice),
			depositPrice: formatPrice(
				depositPrice +
					computeFees({
						amount: depositPrice,
						stripeFees
					}),
				true
			),
			depositPriceWithoutFees: Math.round(depositPrice),
			depositRest: formatPrice(totalPrice - depositPrice, true),
			depositRateToApply
		};
	}
	return {
		totalPrice: formatPrice(totalPrice, true),
		depositPrice: formatPrice(depositPrice, true),
		depositRest: formatPrice(totalPrice - depositPrice, true),
		totalPriceWithoutFees: Math.round(totalPrice),
		depositPriceWithoutFees: Math.round(depositPrice),
		depositRateToApply
	};
}
