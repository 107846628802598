import useStyles from 'isomorphic-style-loader/useStyles';
import { useState, useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './accordion.module.scss';
import { Icon } from '@planity/ui';

export function Accordion({
	title = 'Lorem ipsum',
	className,
	children = 'Nullam id dolor id nibh ultricies vehicula ut id elit. Nullam quis risus eget urna mollis ornare vel eu leo. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Integer posuere erat a ante venenatis dapibus posuere velit aliquet.',
	canToggle = true,
	isCompact = false,
	isVisible = false
}) {
	useStyles(styles);
	const content = useRef();
	const [isCollapsed, setIsCollapsed] = useState(canToggle);

	const cx = classNames.bind(styles);
	const classes = classNames.bind(styles)({
		accordion: true,
		isCollapsed,
		[className]: className !== undefined,
		canToggle,
		isCompact,
		isVisible
	});

	return (
		<div className={classes}>
			{title && (
				<button
					className={styles.toggle}
					onClick={() => {
						setIsCollapsed(!isCollapsed);
					}}
					disabled={!canToggle}
				>
					<span>{title}</span>
					{canToggle && (
						<Icon className={styles.icon} icon='ChevronLeft' size={'medium'} />
					)}
				</button>
			)}
			<div
				className={cx({
					content: true,
					isCollapsed: !isCollapsed
				})}
				ref={content}
			>
				<div className={styles.childrenWrapper}>{children}</div>
			</div>
		</div>
	);
}
